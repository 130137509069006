import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export function EmailConfirm() {
    const params = useParams();
    const [text, setText] = useState("");

    useEffect(() => {
        async function fetchAPI() {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/email/confirm/${params.id}`,
                );
                if (!response.ok) {
                    throw new Error("Not ok");
                }
                setText("Your email has been confirmed. You can now close this page.");
            } catch {
                setText("Error confirming your email address.");
            }
        }

        fetchAPI();
    }, [params.id]);

    return (
        <div>
            <p>{text || "Loading..."}</p>
        </div>
    );
}
