import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Root } from "./routes/root";
import { EmailConfirm } from "./routes/email-confirm";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Root />} />
                        <Route path="/email/confirm/:id" element={<EmailConfirm />}></Route>
                    </Routes>
                </BrowserRouter>
            </header>
        </div>
    );
}

export default App;
