import { default as DownloadButtonSVG } from "../download-button.svg";
import { useEffect, useState } from "react";

export function Root() {
    const [text, setText] = useState("");

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                const json = data.message;
                setText(json);
            });
    });

    return (
        <div>
            <p>{text || "Loading..."}</p>
            <a href="https://apps.apple.com/us/app/outread-speed-reader/id778846279">
                <img src={DownloadButtonSVG} alt="Download on the App Store" width="150" />
            </a>
        </div>
    );
}
